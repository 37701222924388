.FormSubmitWrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  /* align-items: center; */
  width: 100vw;
  /*height: 80vh;*/
  /* background-color: yellow; */
}
.formSubmitContainer {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* border: 1px solid black; */
}
.formSubmitImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-radius: 10px; */
  background-color: rgba(40, 150, 233, 1);
}
.formSubmitBottomWrapper {
  /* height: 50%; */
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px 40px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.submitHeader {
  font-weight: bolder;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitDescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.labelWrapper {
  padding: 10px 40px;
  cursor: pointer;
}
.responseLower {
  text-align: center;
}
.btnContainer {
  width: 80%;
  padding: 20px 0;
  border-radius: 10px;
}
.btnCont1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.subTitle {
  text-align: center;
}
@media only screen and (max-width: 769px) {
  .formSubmitContainer {
    width: 80%;
  }
  .btnCont1{
    justify-content: center;
  }
  .btnContainer{
    width: 100%;
  }
}
