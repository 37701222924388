.mainContainer {
    width: 100%;
    height: 90vh;
    position: relative;
}

.iFrame {
    width: 100%;
    height: 89vh;
    margin-top: -40px;
    /*pointer-events: none;*/
}

.upperDrop {
    position: absolute;
    width: 100vw;
    height: 100vh;
    /*background: red;*/
}
.blockage {
    position: absolute;
    /*background: red;*/
    width: 30%;
    height: 100%;
    right: 0px;
}
.blockage2 {
    position: absolute;
    /*background: red;*/
    width: 30%;
    height: 100%;
    left: 0px;
}
.bottomBlock {
    position: absolute;
    /*background: red;*/
    width: 100%;
    height: 100px;
    left: 0px;
}
.topBlock {
    position: absolute;
    /*background: red;*/
    width: 100%;
    height: 100px;
    bottom: 0px;
}
