.candidateInfoWrapper {
  display: flex;
  /* padding: 1.2rem; */
  gap: 15px;
  flex-wrap: wrap;

}
.leftField {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  padding: 1.2rem;
}
.imageDesWrapper {
  display: flex;
  gap: 10px;
}
.imageDesWrapper2{
    display: flex;
    justify-content: space-between;
    /* gap: 10px; */
  }
.desWrapper {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  gap: 2px;
}
.candidateImg {
  width: 3rem;
  height: auto;
}
.headingWrapper > span {
  font-weight: 600;
  font-size: 1rem;
}
.rightField {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  padding: 1.2rem;
}
.leftWrapper{
    display: flex;
    gap: 5px;
}
.resumeLink{
  color: #2896E9;
}
.starWrapper{
  display: flex;
  gap: 5px;
  font-size: .875rem;
  font-weight: 600;
  align-items: center;
}
.starimg {
  width: 15px;
  aspect-ratio: 1/1;
}