.employeeLoginWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* width: 100vw; */
  /* height: 100vh; */
}
.applicationImage{
  border-radius: 10px;
  width: 150px;
  aspect-ratio: 1/1;
  /* height: auto; */
}
.flexContainer {
  display: flex;
  align-items: center;

}
.qualificationFormCont {
  flex: 1;
}
.employeeLoginContainer {
  margin-top: 40px;
  width: 80vw;
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginSignupText {
  text-align: left;
}
.QualificationHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}
.firstRow {
  flex: 1;
  display: flex;
  align-items: center;
}
.btnwrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.removeBtn {
  color: red !important;
  font-size: 0.7rem !important;
  margin-left: 20px !important;
}
.bottomSignUp {
  font-size: 14px;
  font-weight: 500;
}
.hideDiv {
  visibility: hidden;
}
.flex1 {
  flex: 1;
  /* min-width: 170px; */
  margin: 0px 5px;
}
.checkBox {
  display: flex;
  justify-content: flex-end;
  font-size: 0.6rem;
  align-items: center;
  color: #2896e9;
  font-weight: 500;
}
.addition {
  color: #2896e9 !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  padding-top: 10px !important;
}
.subText {
  margin: 20px 0px;
  font-weight: 400;
  /*font-size: 18px;*/
  text-align: justify;
}

.bottomContainer {
  position: absolute;
  bottom: 40px;
}

.headingText {
  display: flex;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 2rem;
  margin-bottom: 2px;
  /*font-family: CircularStd-Bold;*/
}

.btnWrap {
  margin-top: 25px;
}
.bottomSignup {
  font-size: 0.8rem;
}

.forgotBtn {
  color: #2005a2 !important;
  font-size: 0.7rem;
  /*font-weight: 600;*/
}

.heading {
  margin-bottom: 30px;
  color: white;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  /*font-family: 'CircularStd-Medium';*/
}

.innerFlex {
  display: flex;
  align-items: center;
}
.icons {
  height: 70px;
  width: 70px;
}

.textRight {
  margin-left: 15px;
}

.titleText {
  font-size: 22px;
  font-family: "CircularStd-Book";
}

.subHeading {
  font-size: 14px;
  /*font-family: CircularStd-Book;*/
}

.negativeSpacing {
  margin-left: -20px;
}
.signContainer {
  width: 80%;
  padding: 20px;
  padding-bottom: 2%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  margin-top: 3%;
  /* max-width: 1000px; */
}
.signContainerExp{
  width: 90%;
  padding: 25px;
  padding-bottom: 2%;
  border-radius: 10px;
  /* box-shadow: 0 0 8px rgb(0 0 0 / 15%); */
  background: white;
  margin-top: 3%;
}
.btnContainer {
  width: 80%;
  padding: 20px;
  padding-right: 0;
  border-radius: 10px;
  margin-top: 3%;
}

.newLinetitle {
  color: #161616;
  font-size: 14px;
}

.logoImg {
  text-align: center;
  width: 100%;
}

.sky {
  /*height: 90px;*/
  width: 100px;
}

.newLine {
  height: 5px;
  width: 30px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.login {
  padding: 15px 60px 15px 60px !important;
  /*background-image:  linear-gradient(to right, #0f49a0);*/
  color: white !important;
  /*font-family: Poppins-Medium;*/
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
  /*text-transform: uppercase;*/
  border: 1px solid white !important;
  margin-top: 15px !important;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  width: 100%;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.ProfileUpperWrapper {
  width: 100%;
  /* background-color: red; */
  border-bottom: 2px solid #ebedf4;
  display: flex;
  justify-content: space-between;
}
.ProfileUpperinfoWrap {
  padding-top: 10px;
}
.edit {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  /* padding: 10px 40px !important; */
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.edit1 {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.profileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.btnCont {
  display: flex;
  justify-content: flex-end;
}
.btnCont1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.disabledBtn{
  background-color: #c1bfbf !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.discriptionWrap {
  display: flex;
  margin-top: 15px;
}
.FormSubmitWrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  /* align-items: center; */
  width: 100vw;
  /*height: 80vh;*/
  /* background-color: yellow; */
}
.formSubmitContainer {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  /* border: 1px solid black; */
}
.formSubmitImageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-radius: 10px; */
  background-color: rgba(40, 150, 233, 1);
}
.formSubmitBottomWrapper {
  /* height: 50%; */
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px 40px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.submitHeader {
  font-weight: bolder;
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitDescription {
  display: flex;
  justify-content: center;
  align-items: center;
}
.labelWrapper{
  padding: 10px 40px;
  cursor: pointer;
}

@media only screen and (max-width:1196px){

  .firstRow {
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }
  .flex1 {
    width: 100% !important;
  }

  .ProfileUpperWrapper {
    width: 100%;
    /* background-color: red; */
    border-bottom: 2px solid #ebedf4;
    display: flex;
    flex-direction: column !important;
  }
}