.ProfileUpperWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.profileimg {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.applicationImage {
  border-radius: 10px;
  width: 100%; 
  max-width: 130px; 
  /* height: auto; */
  aspect-ratio: 1/1;
}

@media screen and (min-width: 560px) {
  .ProfileUpperWrapper {
    flex-direction: row;
  }
}
