.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #ecebfb;
  box-shadow: 0px 20px 80px #00000011;
}
.mainFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  margin-bottom: 8px;
}
.left {
  font-weight: 600;
}
.subheading {
  font-size: 0.75rem;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}
.subheading21 {
  font-size: 0.75rem;
  font-weight: 600;
}
.subheading31 {
  font-size: 0.75rem;
  font-weight: 600;
  flex: 2 1;
}
.subheading41 {
  font-size: 0.75rem;
  font-weight: 600;
  flex: 1 1;
}
.subheading51 {
  font-size: 0.75rem;
  font-weight: 600;
  text-align: end;
  flex: 1 1;
}

.toggle {
  color: #28b1f2;
  text-decoration: underline;
  font-size: 0.75rem;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.total {
  font-size: 0.75rem;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}
.flexWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.des {
  /* margin-top: 10px; */
  font-weight: 400;
  margin: 2px 0;
}
.des2 {
  font-weight: 400;
  flex: 2 1;
}
.oldRegimeValue {
  font-weight: 400;
  flex: 1 1;
}
.newRegimeValue {
  font-weight: 400;
  flex: 1 1;
  text-align: end;
}
.deductionHeading {
  color: #28b1f2;
  font-weight: 600;
  margin-top: 10px;
  font-size: 14px;
  cursor: pointer;
  display: inline-block;
}
.rmvBtn {
  color: #7467f0;
  font-size: 12px;
  /* flex: 1 1; */
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
}
.mainFlex2 {
  display: flex;
  align-items: center;
}
.left1 {
  flex: 1 1;
}
.right2 {
  /* flex: 1 1; */
  margin-top: 10px;
}
.grandTotal {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  font-weight: bold;
  color: #fff;
  background-color: #3d348b;
}
.hrDivider {
  border: 1px solid #b8c5d3;
  margin: 15px 0;
}
.highLighter {
  font-weight: normal;
  font-size: 10px;
}
.inlineFlex {
  display: inline-flex;
  flex: 1 1;
}
.flex1{
  flex: 1 1;
}
.flexContainer{
  display: flex;
  gap: 10px;
}
.decValue, .calValue{
  font-size: 0.75rem;
  min-width: 233px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  flex: 0.5 1;
  text-align: start;
}