.mainContainer {
  padding: 10px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerContainer .title {
  font-size: 1rem;
  font-weight: 600;
}
.bottomLine {
  opacity: 0.4;
  color: grey;
  margin-bottom: 5px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.firstCellFlex {
  display: flex;
  align-items: center;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.btnWrapper{
  flex: 1;
  display: flex;
  align-items: center;
  padding: 7px 10px
}
.btnWrapper21{
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
}
.disabledBtn {
  background-color: #cacaca !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.firstCellFlex .driverImgCont {
  width: 40px;
  height: 40px;
  /*border: 3px solid;*/
  border-radius: 50%;
}
.firstCellFlex img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.firstCellInfo {
  margin-left: 10px;
}

.headerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productName {
  text-transform: capitalize;
}
.productCat {
  text-transform: capitalize;
  font-size: 12px;
}

.belowLabel {
  font-weight: bold;
  margin-left: 10px;
}

.infoTitle {
  margin-bottom: 15px;
  padding-bottom: 5px;
  margin-left: 15px;
  border-bottom: 1px solid;
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 5px;
}

.featured {
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 20px;
  text-transform: capitalize;
  background-color: rgba(250, 139, 12, 0.082);
  color: rgb(250, 139, 12);
}

.industry {
  font-size: 0.7rem;
  font-weight: 600;
}

.userForm {
  margin: 0 30%;
}

.paperBackground {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
}

.lhs {
  flex: 0.5;
  margin-left: 10px;
}

.flex {
  display: flex;
  align-items: center;
}

.dispatchBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.generate {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.awbs {
  font-size: 0.8rem;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.awbs > div {
  flex: 1;
}

.value {
  font-weight: 600;
}

.yearFlex {
  display: flex;
  padding-top: 20px;
  gap: 25px;
}

.down {
  flex: 0.2;
  z-index: 0;
}

.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 1px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}

.download {
  border: 2px solid #29cb97 !important;
  border-radius: 30px !important;
  color: #29cb97 !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  padding: 7px 50px !important;
  text-transform: uppercase !important;
  height: 40px !important;
}

.rightFlex {
  display: flex;
  align-items: center;
}

.drop {
  width: 250px;
  margin-left: 30px;
}

.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

.upperInfo {
  margin-top: 30px;
  margin-left: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
}

.loc {
  margin-left: 15px;
  margin-right: 15px;
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hr {
  font-size: 0.875rem;
  margin-left: 15px;
  margin-right: 15px;
}

.plainPaper {
  border-radius: 5px;
  padding: 0.4rem 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  margin-left: 15px;
}

.approved {
  font-size: 0.7rem;
  font-weight: 600;
}

.topFlex {
  display: flex;
  justify-content: space-between;
}

.newInfo {
  font-size: 0.7rem;
}

.key {
  width: 140px;
  font-weight: 500;
  display: inline-block;
}

.btmFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.6rem;
  margin-top: 15px;
}

.user {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .yearFlex {
    flex-direction: column;
    gap: 10px;
  }
}
