.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.accordionFlex {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  align-items: center;
}
.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 20px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.dFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.8rem;
}
.newContainer {
  font-size: 0.7rem;
}
.heading {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}
.key {
  color: #161616;
  margin-bottom: 7px;
}
.accordionKey {
  color: black;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 0.75rem;
}
.value {
  font-weight: 600;
  color: black;
  display: inline-block;
}
.bangonPaper {
  border-radius: 12px;
  margin: 20px 0;
  background-color: #ecebfb;
  padding: 1.2rem;
}
.accordionContainer {
  margin-bottom: 20px;
}
.greyPaper {
  background-color: #f5f7fb;
  margin: 20px 0;
  padding: 1.2rem;
  border-radius: 12px;
}
.grandTotalKey {
  font-weight: 600;
  color: black;
  display: inline-block;
  font-size: 0.8rem;
}
.grandTotal {
  font-weight: 600;
  color: #3d348b;
  font-size: 0.8rem;
}
.hrDivider {
  border: 1px solid #b8c5d3;
  margin: 20px 0;
}

.PdfBtnWrapper {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 1.2rem 0;
}
.approvedWrapper {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  padding: 1.2rem 0;
}
.editBtn2 {
  margin-left: 15px;
}
.edit {
  color: #e92828 !important;
  border: 2px solid #e92828 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.btnApproveWrapper {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-right: 15px;
}
.editSuccess {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

.approved{
  color: #29CB97;
  margin-top: 15px;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 8px;
}
.rejected{
  color: #FF4D4F;
  margin-top: 15px;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 8px;
}
.fontBold{
  font-weight: 500;
  font-size: 18px;
}
.actionerName{
  font-size: 14px;
  font-weight: 500;
}
.comment{
  margin: 5px 0;
}
.component{
  flex: 2 1;
  font-size: 0.8rem;
  font-weight: 600;
}
.decKey,.calKey {
  font-size: 0.8rem;
  font-weight: 600;
  flex: 0.5 1;
  min-width: 150px;
}
.flex1{
  flex: 1 1;
}
.headContainer{
  display: flex;
  gap: 20px;
}
.flexContainer{
  display: flex;
  gap: 0px;
}
.decValue{
  font-size: 0.75rem;
  min-width: 150px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  flex: 0.5 1;
  text-align: start;
}
.calValue{
  color: #3D348B;
  font-size: 0.75rem;
  min-width: 150px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  flex: 0.5 1;
  text-align: start;
}