.employeeLoginWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.loginSignupText {
    text-align: left;
}

.bottomSignUp {
    font-size: 14px;
    font-weight: 500;
}

.subText {
    margin:20px 0px;
    font-weight: 400;
    /*font-size: 18px;*/
    text-align: justify;
}

.bottomContainer {
    position: absolute;
    bottom: 40px;
}

.headingText {
    display: flex;
    justify-content: center;
   text-align: center;
    color: black;
    font-size: 2rem;
    margin-bottom: 2px;
    /*font-family: CircularStd-Bold;*/
}

.btnWrap{
    margin-top: 25px;
}
.bottomSignup {
    font-size: 0.8rem;
}

.forgotBtn {
    color: #2005A2 !important;
    font-size: 0.7rem;
    /*font-weight: 600;*/
}

.heading{
    margin-bottom: 30px;
    color: white;
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    /*font-family: 'CircularStd-Medium';*/
}

.innerFlex {
    display: flex;
    align-items: center;
}
.icons {
    height: 70px;
    width: 70px;
}

.textRight {
    margin-left: 15px;
}

.titleText {
    font-size: 22px;
    font-family: 'CircularStd-Book';
}

.subHeading {
    font-size: 14px;
    /*font-family: CircularStd-Book;*/
}


.negativeSpacing{
    margin-left: -20px;
}
.signContainer{
    padding: 7%;
    padding-bottom: 10%;
    border-radius: 10px;
    box-shadow: 0 0 8px rgb(0 0 0 / 15%);
    /*padding: 1.2rem;*/
    background: white;
    margin: 10%;
    max-width: 800px;
}



.newLinetitle{
    color: #161616;
    font-size: 14px;
    text-align: center;
}

.logoImg{
   text-align: center;
}

.sky{
    height: 150px;
}

.newLine{
    height: 5px;
    width: 30px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background-image: linear-gradient(265deg , #7467F0,#2896E9) !important;
}
.login {
    padding: 15px 60px 15px 60px!important;
    /*background-image:  linear-gradient(to right, #0f49a0);*/
    color: white !important;
    /*font-family: Poppins-Medium;*/
    background-image: linear-gradient(265deg , #7467F0,#2896E9) !important;
    /*text-transform: uppercase;*/
    border: 1px solid white !important;
    margin-top: 15px !important;
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    width: 100%;
    border-radius: 30px !important;
    font-weight: 600 !important;
}
@media (max-width: 767px) {
    .headingText{
        font-size: 1.2rem;
    }
}