.fillingCardWrapper{
    padding: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #B8C5D3;
    margin-top: 20px;
    font-size: .875rem;
}
.greyCardWrapper{
    padding: 1.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: #EAE8FF 0% 0% no-repeat padding-box;
    border: none;
    margin-top: 20px;
    font-size: .875rem;
}
.titleWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.hyperLink{
    text-decoration: underline;
    color: #28b1f2;
    font-size: .875rem;
    cursor: pointer;
}
.title{
    font-weight: 600;
}
.disabledBtn{
    background-color: #cacaca !important;
    color: #ffffff !important;
    padding: 12px 35px !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
  }

.createBtn{
    background-color: #29CB97 !important;
    color: #ffffff !important;
    padding: 12px 35px !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
}
.btnWrap{
    display: flex;
    align-items: center;
    gap: 30px;
}
.disabledLock{
    display: flex;
    align-items: center;
    gap: 15px;
}
.imgLock{
    /* width: 20px;  */
    aspect-ratio: 1/1;
  }  
  .fnt{
    font-size: .75rem;
    font-weight: 600;
  }
@media (max-width:767px){
    .fillingCardWrapper{
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
    }
    .titleWrapper{
        width: 100%;
    }
    .btnWrap{
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }
    .createBtn{
        width: 100%;
    }
}