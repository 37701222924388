.employeeLoginWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.employeeLoginContainer {
  margin-top: 40px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.logoImg {
  text-align: center;
  width: 100%;
}
.newLine {
  height: 5px;
  width: 30px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.loginSignupText {
  text-align: left;
}
.sky {
  width: 100px;
}
.signContainer {
  width: 80%;
  padding: 20px;
  padding-bottom: 2%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  /* margin-top: 3%; */
  /* max-width: 1000px; */
}
.plainPaper {
  border-radius: 10px;
  width: 80%;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.newContainer {
  font-size: 0.7rem;
}
.heading {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}
.createBtn{
  background-color: #29CB97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.disabledCreatebtn{
  background-color: #bec0bf !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.cleckboxWrapper{
  display: flex;
  font-size: .75rem;
  width: 80%;
}
.checkBox{
  display: flex;
  align-items: flex-start;
  gap: 5px;
  color: #161616;
}
.checkBox > input{
  cursor: pointer;
}
.btnCont {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.low{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
}