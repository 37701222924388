.candidateInfoWrapper{
    display: flex;
    padding: 1.2rem;
}
.leftField{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.imageDesWrapper{
    display: flex;
    gap: 10px;
}
.desWrapper{
    display: flex;
    flex-direction: column;
    font-size: .875rem;
    gap: 2px;
}
.candidateImg{
    width: 3rem;
    height: auto;
}
.headingWrapper > span{
font-weight: 600;
font-size: 1rem;
}
.rightField{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}


@media only screen and (max-width:769px) {
    .headingWrapper > span{
        font-weight: 600;
        font-size: 14px;
    }
    .desWrapper > span{
        font-size: 14px;
    }
    .candidateInfoWrapper{
        display: flex;
        flex-direction: column !important;
    }
    .desWrapper >  a {
        font-size: 14px;
    }
}

@media only screen and (max-width:640px) {
    .headingWrapper > span{
        font-weight: 600;
        font-size: 12px;
    }
    .desWrapper > span{
        font-size: 12px;
    }
    .desWrapper >  a {
        font-size: 12px;
    }
}