.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.heading {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}
.grossTotal {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #ecebfb;
  box-shadow: 0px 20px 80px #00000011;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.des {
  color: #818181;
}
.heading2 {
  font-size: 0.8rem;
  font-weight: 600;
}
.component{
  flex: 2 1;
  font-size: 0.8rem;
  font-weight: 600;
}
.decKey,.calKey {
  font-size: 0.8rem;
  font-weight: 600;
  flex: 0.5 1;
  min-width: 230px;
}
.totalAmount {
  font-size: 0.8rem;
  font-weight: 600;
  min-width: 233px;
  color: #3d348b;
}
.flex {
  display: flex;
}
.flex1 {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
  font-size: 0.75rem;
}
.flex21 {
  display: flex;
  margin-top: 10px;
  /* align-items: center; */
  flex: 1;
  flex-direction: column;
}
.leftInfo {
  font-weight: 600;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.createBtn21 {
  border: 2px solid #29cb97 !important;
  color: #29cb97 !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.btnWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.radioWrap {
  font-size: 0.8rem !important;
}
.mainFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
}
.left {
  font-weight: 600;
}
.total {
  font-size: 0.75rem;
  margin-top: 5px;
  margin-bottom: 10px;
  font-weight: 600;
}

.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 20px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}