.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.title {
  font-size: 0.8rem;
  font-weight: 600;
}
.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 1px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}