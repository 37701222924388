.logoImg {
  text-align: center;
  width: 100%;
}
.userWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  color: #ffffff;
  font-size: 0.875rem;
}
.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.downWrap{
  display: flex;
  flex: 1;
}
.container {
  display: flex;
  gap: 10px;
  font-size: 0.75rem;
  font-weight: 600;
}
.lowerWrap {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.headerWrap21 {
  display: flex;
  justify-content: space-between;
}
.userWrap21 {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ffffff;
  font-size: 0.875rem;
}
.imgWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.Userimg {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.sky {
  height: 100px;
  width: 160px;
}
.loginSignupText {
  text-align: left;
}
.left {
  width: 80%;
}
.headingText {
  display: flex;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 2rem;
  margin-bottom: 2px;
  margin-top: 0;
  /*font-family: CircularStd-Bold;*/
}
.evaluationContainer2 {
  gap: 20px;
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.btnWrap {
  display: flex;
  gap: 10px;
  flex: 1;
  margin-right: 10px;
}
.candidateInfoContainer {
  width: 80%;
  padding: 20px;
  padding-bottom: 2%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.candidateInfo2 {
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* text-align: center; */
  font-size: 0.875rem;
  color: #161616;
}
.printWrap {
  padding: 1.2rem 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.Imgwrap {
  width: 60px;
  aspect-ratio: 1/1;
}
.headWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.desWrap {
  color: #ffffff;
  font-size: 0.8rem;
}
.candidateInfoContainer2 {
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.parentCont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.candidateInfo {
  width: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* text-align: center; */
  font-size: 0.875rem;
  color: #161616;
}
.spanWrap {
  font-size: 0.75rem;
  margin-bottom: 10px;
}
.btnWrap21 {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.profileImg {
  width: 40px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.btnWrapper {
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.horizontalLine {
  margin-top: 15px !important;
  border: 1px solid #e3e3ec;
}
.upperWrap {
  display: flex;
  flex-direction: column;
}
.mutliWrap {
  /* display: flex; */
  align-items: center;
  gap: 5px;
}
.removeBtn {
  color: red !important;
  font-size: 0.7rem !important;
  margin-left: 20px !important;
}
.addition {
  color: #2896e9 !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  padding-top: 10px !important;
}
.editSuccess {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 7px 25px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
  font-size: 0.875rem;
  height: 35px;
  white-space: nowrap;
}
.subHeading {
  font-weight: 600;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 10px;
}
.achorImg{
  margin-top: 10px;
  font-size: .8rem;
  margin-bottom: 10px;
}
.rankingWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 10px;
}
.heading {
  font-weight: 600;
  font-size: 0.875rem;
}
.headingDes {
  font-size: 0.875rem;
}
.emojCard {
  /* flex: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}
.emojCard > span {
  font-size: 0.875rem;
}
.performanceindex {
  font-weight: 600;
}
.horizontalLine {
  border-bottom: 2px solid #ebedf4;
  /*margin-top: 15px;*/
}
.btnContainer {
  width: 80%;
  padding: 20px 0;
  border-radius: 10px;
}
.btnCont1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

@media only screen and (max-width: 769px) {
  .headingText {
    display: flex;
    justify-content: center;
    text-align: center;
    color: black;
    font-size: 1.4rem;
    margin-bottom: 2px;
    /*font-family: CircularStd-Bold;*/
  }
  .heading {
    font-weight: 600;
    font-size: 14px;
  }
  .headingDes {
    font-size: 14px;
  }
  .btnContainer {
    display: flex;
    justify-content: center !important ;
    width: 80%;
    padding: 20px 0;
    border-radius: 10px;
  }
  .downWrap{
    flex-direction: column;
    gap: 15px;
    margin-top: 10px;
  }
  .editSuccess{
    font-size: .8rem !important;
    padding: 5px 20px !important;
    justify-content: space-between !important;
  }
}

/* @media only screen and (max-width: 640px) {
  .heading {
    font-weight: 600;
    font-size: 12px;
  }
  .headingDes {
    font-size: 12px;
  }
} */
