.evaluationFormWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #fbfbfb 0% 0% no-repeat padding-box;
  height: 100vh;
  /* width: 80%; */
}
.evaluationContainer {
  margin-top: 40px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.logoImg {
  text-align: center;
  width: 100%;
}

.sky {
  height: 90px;
  width: 100px;
}
.loginSignupText {
  text-align: left;
}
.headingText {
  display: flex;
  justify-content: center;
  text-align: center;
  color: black;
  font-size: 2rem;
  margin-bottom: 2px;
  /*font-family: CircularStd-Bold;*/
}
.evaluationContainer2 {
  gap: 20px;
  /* width: 70%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.candidateInfoContainer {
  width: 80%;
  padding: 20px;
  padding-bottom: 2%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.candidateInfoContainer2 {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
  background: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.rankingWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 10px;
}
.heading {
  font-weight: 600;
}
.headingDes {
  font-size: 0.875rem;
}
.horizontalLine {
  border-bottom: 2px solid #ebedf4;
  margin-top: 15px;
}
.btnContainer {
  /* width: 80%; */
  padding: 20px 0;
  border-radius: 10px;
}
.btnCont1 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.disabledBtn{
  background-color: #cacaca !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.delcarationWrapper {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1.2rem;
  gap: 10px;
}
.checkboxWrapper {
  display: flex;
  gap: 5px;
}
.checkboxWrapper > span{
  color: #161616;
}
.btnCont1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.newLine {
  height: 5px;
  width: 30px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}

@media only screen and (max-width:769px) {
  .btnContainer{
    display: flex;
    justify-content: center;
  }
}