.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 1px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.subTitle{
  font-size: 0.875rem;
}
.dFlex{
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}
.dFlex2{
  display: flex;
  align-items: center;
  justify-content: start;
}
.dFlexGroup2{
  padding: 0 10px;
  flex: 1 1;
}
.btn {
  margin-left: 30px;
}
.horizontalDivider{
  border: 1px solid #B8C5D3;
  margin: 10px;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainText{
  margin-left: 10px;
}
.title{
  font-size: 0.8rem;
  font-weight: 600;
}
.container{
  min-width: 50%;
  min-height: 150px;
}
@media  screen and (max-width:769px) {
  .width {
      width: 80vw;
  }
  #mobileResponsiveWeb{
      display: none !important;
  }
}
