.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.heading {
  font-size: 0.8rem;
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 600;
}
.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.newContainer {
  font-size: 0.7rem;
}
.commentWrap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.headerWrap21 {
  display: flex;
  justify-content: space-between;
}
.candidateInfo2 {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  background-image: url("../../../assets/img/form_img/appreciation_bg@2x.png");
  gap: 40px;
}
.rightWrap {
  display: flex;
  align-items: center;
}
.Imgwrap {
  max-height: 30px;
  /* width: 60px;
  aspect-ratio: 1/1; */
}
.headWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.desWrap {
  color: #ffffff;
  font-size: 0.8rem;
}
.Userimg {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}
.sky {
  height: 100px;
  width: 160px;
}
.userWrap21 {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #ffffff;
  font-size: 0.875rem;
}
.imgWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.headerWrap21 {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.mainFlex {
  display: flex;
  font-size: 0.75rem;
  margin-top: 20px;
}
.verti {
  border: 1px solid #b8c5d3;
  margin: 10px 0;
}

.key {
  color: #818181;
  font-weight: 500;
  margin-bottom: 7px;
}
.key221 {
  margin-top: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
  /* color: #818181; */
  font-weight: 500;
  margin-bottom: 7px;
}
.value {
  font-weight: 600;
  width: 180px;
  color: black;
  display: inline-block;
}
.left {
  flex: 1.4;
}

.right {
  flex: 1;
}

.outerFlex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding-bottom: 30px;
}
.claimListWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 20px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.btnWrapper {
  width: 20%;
  background-color: #ffffff;
}
.PdfBtnWrapper {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 1.2rem 0;
}
.approvedWrapper {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
  padding: 1.2rem 0;
}
.editBtn2 {
  margin-left: 15px;
}
.edit {
  color: #e92828 !important;
  border: 2px solid #e92828 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.editSuccess {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.pdfBtnShared {
  background: #ffffff;
  width: 100%;
  height: 8%;
  position: absolute;
  bottom: 0px;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.btnApproveWrapper {
  display: flex;
  align-items: center;
  gap: 25px;
  margin-right: 15px;
}

.commentContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 0.7rem;
}
.commentDate {
  font-weight: 600;
}

.heading221 {
  font-size: 0.8rem;
  font-weight: 600;
}
.vertical {
  border-left: 1px dashed #b8c5d3;
  max-height: 100px;
  flex: 0.3;
}

.key {
  color: #818181;
  font-weight: 500;
  margin-bottom: 7px;
}
.key221 {
  margin-top: 15px;
  display: flex;
  gap: 20px;
  align-items: center;
  /* color: #818181; */
  font-weight: 500;
  margin-bottom: 7px;
}
.value {
  font-weight: 600;
  width: 180px;
  color: black;
  display: inline-block;
}
.horizontal {
  border-bottom: 1px dashed #b8c5d3;
  margin-top: 15px;
  margin-bottom: 22px;
}

.posted {
  font-size: 0.7rem;
  margin-left: 10px;
}
.statusContainer {
  display: flex;
  justify-content: space-between;
}
.editBtn {
  display: flex;
  justify-content: flex-end;
}

.editFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusFlex {
  display: flex;
  justify-content: flex-end;
}

.btnCont {
  display: flex;
  justify-content: flex-end;
}

.detailFlex {
  display: flex;
}

.info {
  margin-left: 10px;
}
.formDetailWrapper {
  margin-top: 20px;
}
.prcReportWrap {
  margin-top: 10px;
  display: flex;
  border-top: 1px solid #b8c5d3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list {
  color: #818181;
}
.totalWrap {
  width: 100%;
  height: 50px;
  background: #f5f7fb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 5px;
}
.inner {
  margin-left: 20px;
}
.inner > span {
  margin-left: 10px;
  color: #2896e9;
}
.otherWrap {
  margin: 10px 0;
}
.verti {
  border: 1px solid #b8c5d3;
  margin: 10px 0;
}
@media (max-width: 767px) {
  .PdfBtnWrapper {
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 10px;
  }
  .btnApproveWrapper {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-left: 15px;
    gap: 10px;
  }
  .value {
    width: 170px;
  }
  .lowerApp {
    width: 100%;
  }
}
