.mainContainer {
    /* width: 25vw; */
    /*width: 100%;*/
    /* height: 100vh; */
    position: relative;
    /*display: flex;*/
    /* align-items: center; */
    /*flex-direction: column;*/
    padding: 10px 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
}
.awardWrrap{
    /* width: 100vw; */
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
}
.imgWrap > img{
    width: 110px;
    /* height: 70px; */
    /* aspect-ratio: 1/1; */
}
.upper{
    display: flex;
    align-items: center;
}
.heading{
    font-size: .9rem;
    font-weight: 600;
    margin: 0 15px;
}
.textWrap{
    display: flex;
    flex-direction: column;
    font-size: .9rem;
    align-items: center;
    /* gap: 20px; */
    /* justify-content: space-evenly; */
    font-weight: 500;
}
.wrap{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.sigWrap{
  margin-top: 50px;
}
.sigWrapLower{
  margin-top: 15px;
}
.imgWrapSign{
  width: 110px;
  margin-bottom: 15px;
}
.hyperlinkText{
  color: #2896E9;
  text-decoration-line: underline;
  font-weight: 500;
  /* cursor: pointer; */
}
.verti{
    border: 1px solid #B8C5D3;
    margin:10px 15px;
    width: calc(100% - 40px);
  }
  .infoCont{
    font-size: .85rem;
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 500;
    margin: 10px 15px;
  }
  .dateWrap{
    margin-right: 35px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: .875rem;
    /* height: 70px; */
  }
  .upperWrapper{
    display: flex;
    justify-content: space-between;
  }
.graphCont {
      width: calc(100% - 40px);
    height: 340px;
  }
.txtJustify {
    text-align: justify;
    width: calc(100% - 40px);
    font-size: .8rem;
    margin-left: 15px;
    margin-right: 15px;
}
.txtJustify2{
  text-align: justify;
    width: calc(100% - 40px);
    font-size: .8rem;
    margin: 10px 15px;
}
.typeContainer{
  page-break-before: always;
}
.staticGraph {
    width: 600px;
    height: 300px;
}
/* @media print {
  body {
    margin-top: 15px;
    margin-bottom: 15px;
  }
} */
@media print {
  @page {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}