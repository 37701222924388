.mainContainer {
  padding: 10px;
}
.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerContainer .title {
  font-size: 0.8rem;
  font-weight: 600;
}
.bottomLine {
  opacity: 0.4;
  color: grey;
  margin-bottom: 5px;
}
.firstCellFlex {
  display: flex;
  align-items: center;
}
.textStyles {
  display: none;
}
.firstCellFlex .driverImgCont {
  width: 40px;
  height: 40px;
  /*border: 3px solid;*/
  /*border-radius: 50%;*/
}
.firstCellFlex img {
  width: 40px;
  height: 40px;
  /*border-radius: 50%;*/
}
.firstCellInfo {
  margin-left: 10px;
}
.hyperlinkText {
  color: #28b1f2;
  text-decoration-line: underline;
  cursor: pointer;
}
.headerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productName {
  text-transform: capitalize;
}
.productCat {
  text-transform: capitalize;
  font-size: 12px;
}

.belowLabel {
  font-weight: bold;
  margin-left: 10px;
}

.infoTitle {
  margin-bottom: 15px;
  padding-bottom: 5px;
  margin-left: 15px;
  border-bottom: 1px solid;
  width: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 5px;
}

.featured {
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 20px;
  text-transform: capitalize;
  background-color: rgba(250, 139, 12, 0.082);
  color: rgb(250, 139, 12);
}

.industry {
  font-size: 0.7rem;
  font-weight: 600;
}

.userForm {
  margin: 0 30%;
}

.paperBackground {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-color: white;
}

.lhs {
  flex: 0.5;
}

.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 1px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
#colorIcon{
  color: #2896E9 !important;
}
@media (max-width: 767px) {
  .textStyles {
    display: block;
    font-size: 0.8rem;
    margin-left: 5px;
    font-weight: 500;
    color: #2896e9;
  }
}
