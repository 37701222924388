.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0px 20px 80px #00000011;
}
.accordionFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 20px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.flex {
  display: flex;
  font-size: 0.8rem;
  align-items: center;
  margin-bottom: 8px;
}
.flexCon {
  display: flex;
  align-items: center;
}
.dedValue {
  font-weight: 600;
  color: black;
  display: inline-block;
  flex: 1 1;
}
.leftCon {
  flex: 1 1;
}
.rightCon {
  flex: 1 1;
}
.rentKey {
  color: #818181;
  margin-left: 5px;
}
.rentValue {
  font-weight: 600;
  color: black;
  display: inline-block;
}
.dFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  font-size: 0.8rem;
}
.newContainer {
  font-size: 0.7rem;
}
.heading {
  margin-top: 5px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 0.8rem;
}
.key {
  color: #161616;
  margin-bottom: 7px;
  flex: 1 1;
}
.accordionKey {
  color: black;
  display: inline-block;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 0.75rem;
}
.value {
  font-weight: 600;
  color: black;
  display: inline-block;
}
.bangonPaper {
  border-radius: 12px;
  margin: 20px 0;
  background-color: #ecebfb;
  padding: 1.2rem;
}
.accordionContainer {
  margin-bottom: 20px;
}
.greyPaper {
  background-color: #f5f7fb;
  margin: 20px 0;
  padding: 1.2rem;
  border-radius: 12px;
}
.grandTotalKey {
  font-weight: 600;
  color: black;
  display: inline-block;
  font-size: 0.8rem;
}
.grandTotal {
  font-weight: 600;
  color: #3d348b;
  font-size: 0.8rem;
}
.hrDivider {
  border: 1px solid #b8c5d3;
  margin: 20px 0;
}
.approved {
  color: #29cb97;
  margin-top: 15px;
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 8px;
}
.fontBold {
  font-weight: 500;
  font-size: 18px;
}
.approverName {
  font-size: 14px;
  font-weight: 500;
}
.evidence {
  color: #28b1f2;
  text-decoration: underline;
  font-size: 0.75rem;
  flex: 1 1;
  cursor: pointer;
  margin-bottom: 10px;
}
.dedTotalValue{
  font-weight: 600;
  color: black;
  display: inline-block;
  text-align: end;
}