.claimsDetailWrapper {
    display: flex;
    flex-direction: column;
  }
  .outerFlex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
  }
  .subText{
    font-size: 0.7rem;
    margin-left: 5px;
    color: #E92828;
  }
  .openIconResponse{
    color: #29CB97 !important;
  }
  .subTextResponse{
    font-size: 0.7rem;
    margin-left: 5px;
    color: #29CB97;
  }
  .newLine {
    height: 4px;
    width: 30px;
    border-radius: 10px;
    margin-left: 20px;
    background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
  }
  .editBtnWrap {
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: center;
  }
  
  .teck {
    margin-left: 15px;
    font-size: 0.8rem;
    font-weight: 500;
    color: #818181;
  }
  .plainPaper {
    border-radius: 10px;
    padding: 1.2rem;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 20px 80px #00000011;
  }
  .createBtn {
    background-color: #29cb97 !important;
    color: #ffffff !important;
    padding: 12px 35px !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    white-space: nowrap !important;
  }
  .heading {
    font-size: 0.8rem;
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  
  .mainFlex {
    display: flex;
    font-size: .7rem;
    /*align-items: center;*/
  }
  
  .vertical {
    border-left: 1px dashed #b8c5d3;
    max-height: 100px;
    flex: 0.3;
  }
  .hyperlinkText {
    color: #2896e9;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .heading {
    font-size: 0.8rem;
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
  }
  .heading221 {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .mainFlex {
    display: flex;
    /*align-items: center;*/
  }
  
  .vertical {
    border-left: 1px dashed #b8c5d3;
    max-height: 100px;
    flex: 0.3;
  }
  .verticalLine {
    border: 1px solid #b8c5d3;
    margin: 20px 0 10px 0;
  }
  .key {
    color: #818181;
    font-weight: 500;
    margin-bottom: 7px;
  }
  .key221 {
    margin-top: 15px;
    display: flex;
    gap: 20px;
    align-items: center;
    /* color: #818181; */
    font-weight: 500;
    margin-bottom: 7px;
  }
  .value {
    font-weight: 600;
    width: 150px;
    color: black;
    display: inline-block;
  }
  .hyperlinkText {
    color: #2896e9;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .newContainer {
    font-size: 0.7rem;
  }
  .openIcon{
    color: #E92828 !important;
  }
  .icon{
    display: flex !important;
    gap: 10px !important;
  }
  .left {
    /*margin-right: 30px;*/
    flex: 1.4;
  }
  
  .right {
    /*margin-left: 60px;*/
    flex: 1;
  }
  
  .horizontal {
    border-bottom: 1px dashed #b8c5d3;
    margin-top: 15px;
    margin-bottom: 22px;
  }
  
  .posted {
    font-size: 0.7rem;
    margin-left: 10px;
  }
  .statusContainer {
    display: flex;
    justify-content: space-between;
  }
  .editBtn {
    display: flex;
    justify-content: flex-end;
  }
  .status{
    margin-left: -10px !important;
  }
  .edit {
    color: #29cb97 !important;
    border: 2px solid #29cb97 !important;
    padding: 10px 40px !important;
    border-radius: 30px !important;
    font-weight: 600 !important;
  }
  
  .editFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .statusFlex {
    display: flex;
    justify-content: flex-end;
  }
  
  .btnCont {
    display: flex;
    justify-content: flex-end;
  }
  .createBtn {
    background-color: #29cb97 !important;
    color: #ffffff !important;
    padding: 12px 35px !important;
    font-size: 0.8rem !important;
    border-radius: 30px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
  }
  
  .detailFlex {
    display: flex;
  }
  
  .info {
    margin-left: 10px;
  }
  .formDetailWrapper {
    margin-top: 20px;
  }
  .prcReportWrap {
    margin-top: 10px;
    display: flex;
    border-top: 1px solid #b8c5d3;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .list {
    color: #818181;
  }
  .coWrap {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .coName {
    color: #818181;
    font-weight: 500;
    margin-bottom: 7px;
  }
  
  .approvedWrapper {
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-radius: 10px;
    padding: 1.2rem 0;
  }
  .PdfBtnWrapper {
    background: #ffffff;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 1.2rem 0;
  }
  .edit {
    color: #e92828 !important;
    border: 2px solid #e92828 !important;
    padding: 10px 40px !important;
    border-radius: 30px !important;
    font-weight: 600 !important;
  }
  .left221{
    flex: 1.4;
    display: flex;
    gap: 15px;
  }
  .claimimg{
    width: 50px;
    aspect-ratio: 1/1;
    border-radius: 50%
  }
  @media only screen and (max-width:768px){
    .mainFlex{
        display: flex;
        flex-direction: column;
    }  
    .left221{
        display: flex;
        flex-direction: column !important; 
    }
    .leftPartMarginMobile{
        flex: 1;
    }
    .imageContainer{
        display: flex;
        justify-content: center !important;
        align-items: center !important;
    }
    .nameData{
        display: none;
    }
    .left{
        display: flex;
        justify-content: space-between !important;
        margin-top: 10px;
    }
    .verticalLine{
        border-left: 1px dashed #B8C5D3;
        max-height: 120px;
        margin: 0 40px;
        width: 2px;
    }
    .value{
        font-weight: 600;
        color: black;
        display: inline-block; 
        width:100px !important;  
     }
     .nameHigher{
        flex: 1;
        display: flex !important;
        flex-direction: column;
    }
    .verticalLine {
        border-left: 1px dashed #B8C5D3;
        max-height: 100px;
    }
    .key{
        display: flex;
        flex-direction: column !important;
    }
    .claimimg{
        height: 100px;
        width: 100px !important;
        border-radius: 50%
    }
  }
  
  
  @media only screen and (min-width:769px) and (max-width:1024px){
    .value{
        font-weight: 600;
        color: black;
        display: inline-block; 
        width:80px !important;  
     }
     .key{
        display: grid;
        width: 100%;
        grid-template-columns: 60% 40%;
    }
  }
  
  