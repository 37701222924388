.upperFlex{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    margin-top: 15px;
    font-size: 1rem;
    /* margin-left: 20px; */
}

.printFlex{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 30px;
    padding-top: 20px;
}

.btmBtn{
    background-color: #29CB97 !important;
    padding: 15px 50px !important;
    color: white !important;
    border-radius: 5px !important;
}
.tableCont {
    padding: 10px 10px;
    max-height: 200px;
    overflow: scroll;
}
.resetWrapper{
    display: flex;
}
.resetPasswordWrapper{
    padding: 1.2rem;
}
.fieldWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}
.checkBox > label{
    font-size:.75rem
}
.checkBox{
    display: flex;
    align-items: center;
}
.newLine {
    height: 5px;
    width: 30px;
    border-radius: 10px;
    text-align: center;
    margin: auto;
    background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
  }
  .requiredWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width:769px) {
    .printFlex{
        display: flex;
        justify-content: center;
    }
  }