.stickyBtnWrap {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.upperWrap {
  display: flex;
  flex: 2;
}
.upperNum {
  color: #161616;
  font-weight: 400;
}
.btnWrap {
  flex: 1;
  display: flex;
  justify-content: space-around;
  /* margin-right: 40px; */
}
.edit{
  color: #29CB97 !important;
  border: 2px solid #29CB97 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}