.flexContainer {
  display: flex;
  align-items: center;
}
.flex1 {
  flex: 1;
  min-width: 170px;
  margin: 0px 5px;
}

.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
}

.countryField {
  flex: 0.5;
}
.firstRow {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.lastRow {
  flex: 0.5;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  /* align-items: center; */
}
.lastRowflex1 {
  /* flex: 0.5; */
  min-width: 170px;
  margin: 0px 5px;
}
.fieldFlex {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
}

.addBtn {
  border: 1px solid #4a4a4a59 !important;
  width: 100%;
  padding: 15px !important;
  margin-top: 10px !important;
  border-radius: 5px !important;
  font-size: 0.8rem !important;
}

.addBtn:hover {
  background: #f7a728;
  color: white;
}

.dltBtn {
  border: 1px solid red !important;
  color: red !important;
  font-size: 0.8rem !important;
  padding: 10px 20px !important;
  margin-top: 8px !important;
}

.dltBtn:hover {
  background: red;
  color: white !important;
}

.cell {
  margin-bottom: 40px;
}
.headerTitle {
  flex: 4;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sku {
  font-size: 0.8rem;
  margin-left: 40px;
  margin-right: 10px;
}

.skuId {
  display: flex;
  align-items: center;
}

.rightBtn {
  width: 200px;
}

.skuBtn {
  background: #f7a728 !important;
  padding: 11px 16px !important;
  color: white !important;
  border-radius: 5px !important;
  margin-bottom: 15px !important;
}

.removeBtn {
  color: red !important;
  font-size: 0.7rem !important;
  margin-left: 20px !important;
}

.addition {
  color: #2896e9 !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  padding-top: 10px !important;
}
.hideDiv {
  visibility: hidden;
}

.qualificationFormCont {
  flex: 1;
}
.historyfieldWrapper{
  display: flex;
  flex: 1;
}
.historyfieldWrapperHide{
  display: flex;
  flex: 1;
}
.textCenter {
  text-align: center;
}


@media (max-width:1196px) {
  .flexContainer{
    flex-direction: column;
  }
  .qualificationFormCont{
    width: 100%;
  }
  .flex1{
    flex: 1;
    min-width: 0;
  }
  .firstRow{
    flex-direction: column;
  }
  .historyfieldWrapper{
    width: 100%;
    flex-direction: column;
  }
  .btnCont{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  
}