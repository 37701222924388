.upperFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-top: 15px;
  /* margin-left: 20px; */
}

.printFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 10px;
}
.printFlex21 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
  padding-top: 10px;
}
.edit {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 5px 30px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.editGreen {
  color: #29cb97 !important;
  border: 2px solid #29cb97 !important;
  padding: 10px 20px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
  font-size: 0.875rem;
}
.btmBtn {
  background-color: #29cb97 !important;
  padding: 15px 50px !important;
  color: white !important;
  border-radius: 5px !important;
}
.tableCont {
  padding: 10px 10px;
  max-height: 200px;
  overflow: scroll;
}
.headerWrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.resetWrapper {
  display: flex;
}
.newLine {
  height: 5px;
  width: 30px;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.upperFlex21 {
  font-weight: 600;
  font-size: 1.5rem;
}
.resetPasswordWrapper {
  padding: 1.2rem;
}
.fieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.checkBox > label {
  font-size: 0.75rem;
}
.checkBox {
  display: flex;
  align-items: center;
}
.upperFlex2 {
  margin-top: 10px;
  font-weight: 500;
  font-size: 0.87rem;
  text-align: center;
}
