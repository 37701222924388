.newLine {
  height: 4px;
  width: 30px;
  border-radius: 10px;
  margin-left: 20px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.Wrapper {
  width: 100%;
}
.btnWrap {
  margin: 15px 0px;
  display: flex;
  align-items: center;
  gap: 15px;
  justify-content: space-between;
}
.toolWrapper {
  display: flex;
  flex-direction: column;
}
.detailWrap {
  padding: 15px;
}
.containerHead{
  position: sticky;
}
.upperWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: white;
  margin-bottom: 8px;
}
.chip {
  width: 12px !important;
  height: 12px !important;
}
.btnCont {
  display: flex;
  align-items: center;
  gap: 20px;
}
.staffWrap {
  min-width: 150px;
}
.navIcon {
  color: #919bb0;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.navIcon:hover {
  color: #707a90;
}
.dayHeader {
  color: #333;
  text-align: center;
}
.dFlex {
  display: flex;
}
.resetPasswordWrapper {
  padding: 1.2rem;
}
.moreBtn {
  color: blue;
  font-size: 10px;
  margin-left: 10px;
  cursor: pointer;
  flex: 0.5 1;
  text-decoration: underline;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.title {
  font-size: 1rem;
  font-weight: 600;
}
