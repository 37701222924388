.mainContainer {
    padding: 10px;
  }
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .headerContainer .title {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .bottomLine {
    opacity: 0.4;
    color: grey;
    margin-bottom: 5px;
  }
  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .firstCellFlex {
    display: flex;
    align-items: center;
  }
  
  .firstCellFlex .driverImgCont {
    width: 40px;
    height: 40px;
    /*border: 3px solid;*/
    /*border-radius: 50%;*/
  }
  .firstCellFlex img {
    width: 40px;
    height: 40px;
    /*border-radius: 50%;*/
  }
  .firstCellInfo {
    margin-left: 10px;
  }
  
  .headerFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .productName {
    text-transform: capitalize;
  }
  .productCat {
    text-transform: capitalize;
    font-size: 12px;
  }
  
  .belowLabel {
    font-weight: bold;
    margin-left: 10px;
  }
  
  .infoTitle {
    margin-bottom: 15px;
    padding-bottom: 5px;
    margin-left: 15px;
    border-bottom: 1px solid;
    width: 100%;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  
  .heading {
    margin-right: 5px;
  }
  
  .featured {
    font-size: 12px;
    padding: 3px 10px;
    border-radius: 20px;
    text-transform: capitalize;
    background-color: rgba(250, 139, 12, 0.082);
    color: rgb(250, 139, 12);
  }
  
  .industry {
    font-size: 0.7rem;
    font-weight: 600;
  }
  
  .userForm {
    margin: 0 30%;
  }
  
  .paperBackground {
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    background-color: white;
  }
  
  .lhs {
    flex: 0.5;
  }
  .statusContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .newLine {
    height: 4px;
    width: 30px;
    border-radius: 10px;
    margin-left: 1px;
    background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
  }
  