.PdfViewWrapper {
  background: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.innerPdfCont {
  width: 80%;
  height: 100vh;
  background: rgb(235, 235, 234);
  position: relative;
}
.topWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 100%;
  background: #16161663 0% 0% no-repeat padding-box;
}
.editBtn2 {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  gap: 30px;
  margin-left: 20px;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 12px 35px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.edit {
  color: #E92828 !important;
  border: 2px solid #E92828 !important;
  padding: 10px 40px !important;
  border-radius: 30px !important;
  font-weight: 600 !important;
}
.PdfBtnWrapper {
  background: #ffffff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0;
  position: absolute;
  /* top: 0; */
  bottom: 0px;
}
