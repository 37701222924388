.FormSubmitWrapper {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    /* align-items: center; */
    width: 100vw;
    height: 80vh;
    /* background-color: yellow; */
  }
  .formSubmitContainer {
    width: 500px;
    height: 400px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    /* border: 1px solid black; */
  }
  .formSubmitImageWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* border-radius: 10px; */
    background-color: rgba(40, 150, 233, 1);
  }
  .formSubmitBottomWrapper {
    /* height: 50%; */
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px 40px;
    box-shadow: 0 0 8px rgb(0 0 0 / 15%);
    background: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .submitHeader {
    font-weight: bolder;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .submitDescription {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .printFlex{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 30px;
    padding-top: 20px;
}
