.cascaderCont {
    position: absolute; top: 0px; left: 0px; width: 100%;
}
.antCascaderMenus {
    position: fixed;
    z-index: 1050;
    font-size: 14px;
    white-space: nowrap;
    background: #fff;
    border-radius: 4px;
    -webkit-box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}

.cascaderMenu {
    display: inline-block;
    min-width: 111px;
    height: 180px;
    margin: 0;
    padding: 4px 0;
    overflow: auto;
    vertical-align: top;
    list-style: none;
    border-right: 1px solid #e8e8e8;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cascaderMenu:first-child {
    border-radius: 4px 0 0 4px;
}

.antCascaderMenus ul, .antCascaderMenus ol {
    margin: 0;
    list-style: none;
}


.cascaderMenuItemExpand {
    position: relative;
    padding-right: 24px;
}

.cascaderMenuItem {
    padding: 5px 12px;
    line-height: 22px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.cascaderMenuItemSelected {
    background: #dedede;
}

.cascaderMenuItemExpand .cascaderMenuItemExpandIcon, .ant-cascader-menu-item-loading-icon {
    display: inline-block;
    font-size: 12px;
    -webkit-transform: scale(.83333333) rotate(0deg);
    -ms-transform: scale(.83333333) rotate(0deg);
    transform: scale(.83333333) rotate(0deg);
    position: absolute;
    right: 12px;
    color: rgba(0,0,0,.45);
}

.icon {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon svg {
    display: inline-block;
}
