.inputContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #f4f4f4;
    padding: 0px 5px;
    background: #F8F9FB;
    border-radius: 20px;
}
.searchInput {
    background: transparent;
    width: 100%;
    height: 20px;
    border: none;
    padding: 10px;
    font-size: 0.8rem;
}
.searchInput:focus {
    outline: none;
}
.wwe {
    align-items: center;
    display: flex;
    width: 55% ;
}
/* .Style_wwe__casfu{
    width: "500px !important";
} */