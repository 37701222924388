.RatingWrap {
  background: #f5f7fb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 1.2rem;
  font-size: 0.875rem;
  font-weight: 600;
  gap: 10px;
  display: flex;
  flex-direction: column;
  /*width: 23%;*/
  flex: 1;
}
.value {
  font-size: 2rem;
  font-weight: 500;
}
