body{
  background-color: #F6F7FF;
  font-family: 'Montserrat', sans-serif;
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: url("./assets/fonts/CircularStd-Bold.otf");
}

@font-face {
  font-family: 'CircularStd-Book';
  src: url("./assets/fonts/CircularStd-Book.otf");
}

@font-face {
  font-family: 'CircularStd-Medium';
  src: url("./assets/fonts/CircularStd-Medium.otf");
}
.formFlex {
  display: flex;
  /*margin-bottom: 10px;*/
}
.formFlex1 {
  display: flex;
  /*margin-bottom: 10px;*/
}
.formFlex2 {
  display: flex;
  width: 100%;
  /*margin-bottom: 10px;*/
}
.formGroup1 {
  flex: 1;
  padding: 7px 10px;
  /*padding: 10px 15px*/
}
.formGroup221 {
  flex: 1;
  padding: 7px 10px;
}
.formGroup221 .MuiSelect-selectMenu {
  white-space: normal !important;
}
.newMemberWrapper {
  display: flex;
  flex-direction: row;
  box-shadow: 0px 20px 80px #00000011 !important;
  background: #ebf9ff;
  padding: 3rem 1.2rem;
  /* padding: 2rem 1.5rem; */
  justify-content: space-around;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.newMemberWrapper .slick-prev {
  left: -14px;
}
.newMemberWrapper .slick-next {
  right: -14px;
}
.formGroup2 {
  flex: 1;
  padding: 7px 10px;
  /*padding: 10px 15px*/
}
.formGroup {
  flex: 1;
  padding: 7px 10px;
  /*padding: 10px 15px*/
}
.formGroup1 {
  flex: 1;
  padding: 7px 10px;
  /*padding: 10px 15px*/
}

.file-upload {
  position: relative;
  display: inline-block;
  width: 25%;
  text-align: center;
}

.file-upload__label {
  display: block;
  padding: 10px 5px;
  color: #fff;
  background: #4962fe;
  transition: background 0.3s;
}
.file-upload__label:hover {
  cursor: pointer;
  background: #000;
}

.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.file-upload__input:hover {
  cursor: pointer;
  background: #000;
}

.toursinfo .formGroup > div {
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  margin-top: 5px;
}

.login .MuiInputBase-input {
  /*border-bottom: 1px solid black;*/
}

.login .MuiInputLabel-root {
  color: black;
  background: white;
}

input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.badge {
  color: black;
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  padding: 15px 25px;
}

input[type="radio"]:checked + label {
  color: #8c94ff;
  background: white;
  border: solid 1px #8c94ff;
}

input[type="radio"] + label {
  transition: 0.2s all;
  border: solid 1px #d9d9d9;
  font-size: 0.8rem;
  /*border-left: solid 3px #8c94ff;*/
}
input[type="radio"] + label:hover {
  /*border-left: solid 3px #8c94ff;*/
  color: #8c94ff;
  font-size: 0.8rem;
}

.radio-group {
  /*border: solid 1px #d9d9d9;*/
  /*display: inline-block;*/
  margin: 5px 20px;
  /*border-radius: 10px;*/
  overflow: hidden;
  display: flex;
}

.tableActionBtn {
  padding: 6px !important;
  color: rgb(147, 154, 191) !important;
}
.tableActionBtnEdit {
  padding: 6px !important;
  color: black !important;
  border: 1px solid #2896e9 !important;
  border-radius: 3px !important;
}
.iconTabs .MuiTab-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 0.7rem;
}
.iconTabsEvents .MuiTab-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 0.7rem;
}
.iconTabsEvents {
  flex: 1;
}

.MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-right: 10px;
}

.myprofile .MuiSelect-outlined.MuiSelect-outlined {
  padding: 10px 26px 10px 12px;
}

.midContainer {
  margin: 0 30%;
}

.sub {
  padding: 10px 55px !important;
}

.editAccordion .MuiIconButton-root {
  padding: 0px;
  padding-bottom: 5px;
}

.status {
  display: inline !important;
  font-size: 12px;
  font-weight: 600;
  /*background-color: rgba(134,142,174,.06274509803921569);*/
  padding: 4.5px 11.85px;
  border-radius: 15px;
}

.AutoCompleteWrap {
  margin: 10px 0;
  padding-left: 7.5px;
}
.AutoCompleteWrap
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input {
  padding: 4px;
}
.AutoCompleteWrap .MuiInputLabel-formControl {
  top: -5px;
}
.AutoCompleteWrap .MuiInputLabel-outlined.MuiInputLabel-shrink {
  position: absolute;
  top: 0;
}
.createBtn {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 10px 20px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}
.creatBTN{
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 10px 20px !important;
 
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font: normal normal 600 1.125rem Montserrat !important;
}
.createBtnreset {
  background-color: #29cb97 !important;
  color: #ffffff !important;
  padding: 10px 30px !important;
  font-size: 0.8rem !important;
  border-radius: 30px !important;
  text-transform: uppercase !important;
  font-weight: 600 !important;
}

.plusIcon {
  margin-left: 10px !important;
}

.online {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}
.offline {
  background-color: rgba(222, 232, 217, 0.06);
  color: #dce4da;
}
.success {
  border: 1px solid #2896e9;
  color: #2896e9;
}
.pending_approval{
  border: 1px solid #2005A2;
  color: #2005A2;
}
.error {
  background-color: rgba(255, 77, 79, 0.06274509803921569);
  color: #ff4d4f;
}
.errorBg {
  color: #ffffff;
  background-color: #ff4d4f;
}
.warning {
  border: 1px solid #20c997;
  color: #20c997;
}
.positiveBg {
  color: #ffffff;
  background-color: #20c997;
}
.neutralBg {
  color: #ffffff;
  background-color: #f4881b;
}
.nosourcing {
  border: 1px solid #818181;
  color: #818181;
}
.waiting {
  background-color: rgba(254, 129, 127, 0.06274509803921569);
  color: #fe817f;
}
.Pending {
  border: 1px solid #f4881b;
  color: #f4881b;
}
.priority .MuiOutlinedInput-root {
  position: relative;
  border-radius: 15px;
  font-size: 0.8rem;
}

.log .MuiTypography-body1 {
  font-size: 0.8rem;
}

.innerHtml {
  word-wrap: break-word;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: white;
}
.Selected {
  color: #20c997;
}
.Rejected {
  color: #ff4d4f;
}
.pending {
  background-color: rgba(250, 139, 12, 0.06274509803921569);
  color: #fa8b0c;
}
.on_going {
  background: rgba(44, 153, 255, 0.082);
  color: rgb(44, 153, 255);
}
.completed {
  background: rgba(95, 99, 242, 0.082);
  color: rgb(95, 99, 242);
}
.junk {
  background: #4a4a4a;
  color: #f6f7ff;
}
.converted {
  background-color: #a2a0a0;
  color: #fee253;
}

.Resolved {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}

.Pending {
  background-color: rgba(250, 139, 12, 0.06274509803921569);
  color: #fa8b0c;
}

.tableActionBtn {
  padding: 6px !important;
  color: black !important;
  border: 1px solid #2896e9 !important;
  border-radius: 3px !important;
  margin-right: 10px !important;
}
.tableActionBtnError {
  padding: 6px !important;
  color: #e92828 !important;
  border: 1px solid #e92828 !important;
  border-radius: 3px !important;
  margin-right: 10px !important;
}
.tableActionBtn_PENDING {
  padding: 6px !important;
  color: #f4881b !important;
  border: 1px solid #f4881b !important;
  border-radius: 3px !important;
  margin-right: 10px !important;
}
.tableActionBtnSuccess {
  padding: 6px !important;
  color: #29cb97 !important;
  border: 1px solid #29cb97 !important;
  border-radius: 3px !important;
  margin-right: 10px !important;
}

.pending_lead {
  background-color: #f274744f;
  color: #f44336;
}

.new_lead {
  background-color: #f44336;
  color: #030303;
}

.junk {
  background: #4a4a4a;
  color: #f6f7ff;
}

.archived {
  background: #4a4a4a;
  color: #f6f7ff;
}

.duplicate {
  background-color: #6e6e6e;
  color: #f6f7ff;
}

.contact_in_future {
  background-color: #a2a0a0;
  color: #fee253;
}

.contacted {
  background-color: #cce8fc;
  color: #055379;
}

.customer {
  background-color: #cdeadc;
  color: #2caa6f;
}

.in_progress {
  background-color: #cce8fc;
  color: #055379;
}

.lost_to_competetion {
  background-color: #6e6e6e;
  color: #f6f7ff;
}

.attempted_to_contact {
  background-color: #f44336;
  color: #030303;
}

.demo_fixed {
  background-color: #d4fad9;
  color: #094329;
}

.quote_sent {
  background-color: #d4fad9;
  color: #073507;
}

.unqualified {
  background-color: #4a4a4a;
  color: #f6f7ff;
}

.call_aligned {
  background-color: #cdeadc;
  color: #2caa6f;
}

.request_received {
  background-color: #d4fad9;
  color: #094329;
}

.demo_scheduled {
  background-color: #cce8fc;
  color: #055379;
}

.demo_completed {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}

.LOW {
  background-color: #cce8fc;
  color: #055379;
}

.MEDIUM {
  background-color: #eebb53;
  color: white;
  /*color: #fa8b0c;*/
}

.HIGH {
  background-color: #ee5353;
  color: white;
}

.Dispatched {
  background-color: #cce8fc;
  color: #055379;
}

.Received {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}

.Delivered {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}
/*GOOGLE MAP STYLES*/
.googlePlaceContainer {
  margin-top: 15px;
}
.Demo__map-maker-icon {
  color: #fd6c6c;
}

.Demo__github-icon {
  font-size: 24px;
}

.Demo__github-link {
  color: #262626;
  font-size: 20px;
}

.Demo__github-link:hover,
.Demo__github-link:active {
  color: #4078c0;
  text-decoration: none;
}

.Demo__suggestion-icon {
  margin-right: 8px;
}

.Demo__search-bar-container {
  /*width: 100%;*/
  /*max-width: 500px;*/
  /*margin: 40px auto 0;*/
}

.Demo__search-input-container {
  position: relative;
}

.Demo__search-input,
.Demo__search-input:focus,
.Demo__search-input:active {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
  border: honeydew;
  display: block;
  width: calc(100% - 32px);
  padding: 16px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
}

.Demo__clear-button,
.Demo__clear-button:active,
.Demo__clear-button:focus {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  color: #999;
}

.Demo__autocomplete-container {
  border-bottom: honeydew;
  border-left: honeydew;
  border-right: honeydew;
  border-top: 1px solid #e6e6e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0 0 2px 2px;
  position: absolute;
  z-index: 2;
}

.Demo__suggestion-item {
  padding: 8px;
  text-align: left;
  background-color: #fff;
  cursor: pointer;
}

.Demo__suggestion-item--active {
  background-color: #fafafa;
}

.Demo__dropdown-footer {
  display: flex;
  justify-content: flex-end;
  padding: 4px;
}

.Demo__dropdown-footer-image {
  display: inline-block;
  width: 150px;
}

.Demo__spinner {
  color: #18bc9c;
  font-size: 30px;
}

.Demo__error-message {
  color: red;
}

.Demo__geocode-result-header {
  font-size: 20px;
  color: #222222;
}
.plainPaper {
  border-radius: 10px;
  padding: 0.4rem 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
}

.headerFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerFlex1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerFlex3 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.wrapper {
  margin-top: 20px;
}
.info_Status {
  display: flex;
  justify-content: center;
  align-items: center;
}
.inner {
  gap: 120px;
}
.slider_wrap {
  display: flex;
}
.tags {
  color: #919bb0;
  margin-right: 10px;
}
.file_Wrapper {
  width: 48.7%;
}
.infoBottomBar {
  border-bottom: 1px solid;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.infoTitle {
  margin-bottom: 10px;
  /*padding-bottom: 5px;*/
  margin-left: 15px;
  /*border-bottom: 1px solid;*/
  width: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}
.infoTitle1 {
  margin-bottom: 10px;
  /*padding-bottom: 5px;*/
  margin-left: 15px;
  /*border-bottom: 1px solid;*/
  width: 100%;
  font-weight: 600;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 5px;
}
.heading1 {
  margin-right: 5px;
}

.heading_stats {
  margin-right: 30px;
  /* height: 22px; */
  font-size: medium;
}

/**/

/*.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {*/
/*    border-color: #f44336 !important;*/
/*}*/

.darkColor .MuiInputBase-root.Mui-disabled {
  color: black !important;
}

.leadCircle {
  color: #f7a728 !important;
}

.smallCheckbox .MuiFormControlLabel-label {
  font-size: 0.8rem;
}

.MuiTableCell-stickyHeader {
  background: #ecf7ff !important;
}

.recharts-text {
  font-size: 0.7rem;
}

.totalinfo .formGroup > div {
  font-size: 0.8rem;
  font-weight: 600;
  margin-left: 5px;
  margin-top: 5px;
}

.totalinfo label {
  font-size: 0.8rem;
}

.supportInfo label {
  font-weight: 600;
  font-size: 0.8rem;
}

.bottomAction .MuiDrawer-paper {
  z-index: 10 !important;
}

.MuiCollapse-wrapperInner .MuiListItemText-root {
  font-weight: 300 !important;
}

.onboarded {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}

.in_transit {
  background-color: #cce8fc;
  color: #055379;
}

.advanceField .MuiFormHelperText-root.Mui-error {
  font-size: 9px;
}
.imageGalleryWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 20px 80px #00000011;
  border-radius: 10px;
  padding: 1.2rem;
}

.imageGalleryWrapper .MuiAccordion-root:before {
  background-color: transparent !important;
}
.line-container {
  display: flex;
  width: 100%;
  margin: 0px auto;
  align-items: center;
}
.DeclarationHeading {
  font-weight: bolder;
}
.line {
  flex-grow: 1;
  height: 1px;
  background: black;
  position: relative;
}

.line.arrow-right:after {
  position: absolute;
  content: "";
  bottom: -8px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid black;
}

/*.MuiFormLabel-root{*/
/*    font-size: 0.8rem !important;*/
/*}*/

/*Sales colors*/
.pending_sales {
  background-color: #f274744f;
  color: #f44336;
}
.confirmed {
  background: rgba(44, 153, 255, 0.082);
  color: rgb(44, 153, 255);
}
.cancelled {
  background-color: #f44336;
  color: white;
}
.processing {
  background: #4a4a4a;
  color: #f6f7ff;
}
/*.active{*/
/*    background-color: rgba(32,201,151,.06274509803921569);*/
/*    color: #20c997;*/
/*}*/
.failed {
  background: rgba(95, 99, 242, 0.082);
  color: rgb(44, 153, 255);
}
.on_going {
  background-color: rgba(250, 139, 12, 0.06274509803921569);
  color: #fa8b0c;
}
/*Sales Calling colors*/
.pending_calling {
  background-color: #f274744f;
  color: #f44336;
}
.cancelled_calling {
  background-color: #f44336;
  color: white;
}
.assigned_calling {
  background-color: #a2a0a0;
  color: #fee253;
}
.reattempt_calling {
  background-color: rgba(250, 139, 12, 0.06274509803921569);
  color: #fa8b0c;
}
.confirmed_calling {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}
.no_response_calling {
  background: rgba(95, 99, 242, 0.082);
  color: rgb(95, 99, 242);
}

.PAY_ON_DELIVERY {
  background: rgba(95, 99, 242, 0.082);
  color: rgb(44, 153, 255);
}

.PREPAID {
  background-color: rgba(32, 201, 151, 0.06274509803921569);
  color: #20c997;
}

.menuItem {
  color: #7467f0;
}

.step {
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: white;
}

.v-stepper {
  position: relative;
  /*   visibility: visible; */
}

.step .circle {
  background-color: white;
  border: 1px solid gray;
  border-radius: 100%;
  width: 10px; /* +6 for border */
  height: 10px;
  display: inline-block;
}

.step .line {
  top: 13px;
  left: 6px;
  /*   height: 120px; */
  height: 100%;

  position: absolute;
  border-left: 3px solid gray;
}

.step.completeds .circle {
  visibility: visible;
  background-color: #b8c5d3;
  border-color: #b8c5d3;
}

.step.completeds .line {
  border-left: 1px solid #b8c5d3;
}

.step.active .circle {
  visibility: visible;
  border-color: #b8c5d3;
}

.step.empty .circle {
  visibility: hidden;
}

.step.empty .line {
  /*     visibility: hidden; */
  /*   height: 150%; */
  top: 0;
  height: 150%;
}

.step:last-child .line {
  border-left: 1px solid #b8c5d3;
  /*z-index: -1; !* behind the circle to completely hide *!*/
}

.filterSearchIcon {
  background-image: linear-gradient(265deg, #7467f0, #2896e9);
  color: white;
  border-radius: 20px;
  padding: 5px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterColor {
  color: #2896e9 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  /*background-image: linear-gradient(265deg , #7467F0,#2896E9) !important;*/
  /*color: white;*/
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}

.txtCenter {
  margin-top: 10px;
  text-align: center;
}

.viewBtn {
  color: #2896e9 !important;
  font-weight: 600 !important;
}

.MuiListItemIcon-root {
  min-width: 32px !important;
}

.MuiTab-root {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
}

.MuiTabs-indicator {
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: black !important;
  font-weight: bold !important;
}

.MuiTableCell-root {
  font-family: "Montserrat", sans-serif;
}

.MuiTableCell-head {
  color: black !important;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.capitalize {
  text-transform: capitalize;
}
.textLayer {
  display: none !important;
}
.CandidateInfoWrappers .MuiInputBase-root.Mui-disabled {
  background: #ebedf4 0% 0% no-repeat padding-box !important;
  color: black !important;
}
.CandidateInfoWrappers .MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: rgba(0, 0, 0, 0.7) !important;
}
.dtMobCard {
}

.dtMobCell {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  border-bottom: 2px solid #f6f7fe;
}
.dtMobCard .dtMobCell:last-child {
  border-bottom: 0px;
}
.dtMobCellValue {
  display: flex;
  font-size: 12px;
  align-items: flex-end;
  width: 100%;
  text-align: end;
  justify-content: end;
}
.dtMobCellValueMultiple {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  align-items: flex-end;
  width: 100%;
  text-align: end;
  justify-content: end;
}
.dtMobCellLabel {
  font-size: 12px;
  font-weight: 600;
  max-width: 50%;
  text-transform: uppercase;
}
.dtMobCellLabelHide {
  display: none;
}
.dTMobilePagination {
  position: fixed;
  bottom: 0px;
  left: 0px;
  background: white;
  width: 100vw;
}

.installDiv {
  display: none;
  position: fixed;
  bottom: 0px;
  width: calc(100% - 30px);
  background: white;
  justify-content: space-between;
  padding: 15px 15px;
  align-items: center;
  text-align: right;
  box-shadow: 0 0 10px rgba(80, 78, 78, 0.15);
}

.installDiv > div {
  font-size: 0.9rem;
}
.installDiv > button {
  border: none;
  background: #1f05a2;
  color: white;
  padding: 8px 15px;
  font-weight: 800;
  font-family: "Montserrat";
}
.installDiv .close {
  border: none;
  background: rgba(128, 128, 128, 0.3);
  padding: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}
.spanTextAnd {
  font-size: 0.75rem;
  font-weight: 500;
}
.installDiv .close:before {
  position: absolute;
  content: "x";
  top: 21px;
  right: 23px;
}
.installDiv img {
  width: 60px;
}
.logoDiv {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .iconTabsEvents {
    padding: 6px 8px !important;
  }
  .mobileNoLabel {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .MemberWrapper {
    max-width: 760px;
  }
  .newMemberWrapper {
    max-width: 700px;
  }
}

@media (min-width: 767px) and (max-width: 1200px) {
  .MemberWrapper {
    max-width: 12000px !important;
  }
}
@media print {
  .fullDetailWrapper {
    display: block !important;
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    margin-left: 0 !important;
  }
}

@media print {
  body {
    visibility: hidden;
  }
  #content-to-print {
    visibility: visible;
    position: absolute !important;
    overflow: visible !important;
    left: 0;
    top: 0;
    width: 100vw !important;
    /*height: 900vh !important*/
  }
  #InfoWrapperCand{
    display: flex;
    flex-direction: column;
  }
  .bottomAction {
    overflow: visible !important;
  }
  #upper_info_wrap{
    flex-direction: row;
  }
  #contentInfo-to-print{
    visibility: visible;
    position: absolute !important;
    overflow: visible !important;
    left: 0;
    top: 0;
    width: 100vw !important;
  }
}

.createBtnOutland {
  border: 2px solid #29CB97 !important;
  color: #29cb97 !important;
  padding: 10px 20px !important;
  border-radius: 23px !important;
  text-transform: uppercase !important;
  font: normal normal 600 1.125rem Montserrat !important;
}

.CandidateInfoWrappers .MuiInputBase-root.Mui-disabled {
  background: #ebedf4 0% 0% no-repeat padding-box !important;
  color: black !important;
}
.CandidateInfoWrappers .MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: rgba(0, 0, 0, 0.7) !important;
}
.calender_Wrapper .MuiPickersCalendarHeader-label,
.calender_Wrapper .MuiPickersDay-root,
.calender_Wrapper .MuiDayCalendar-weekDayLabel,
.calender_Wrapper .MuiPickersYear-yearButton {
  font-size: 0.875rem;
  font-family: Montserrat !important;
 
}
.MuiPickersDay-dayOutsideMonth {
  color: #d8d8dd !important;
}
.calender_Wrapper .MuiPickersYear-yearButton {
  font-size: 0.8rem;
}

.MuiDayCalendar-weekDayLabel{
font-weight: 600 !important;
}
.MuiPickersCalendarHeader-label{
  font-weight: 600 !important;
}
@media (max-width: 767px) {
  .iconTabsEvents {
    padding: 6px 8px !important;
  }
}
/* .css-23p0if-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
  background: #ecf7ff !important;
  border: none !important;
} */
/* .Mui-selected {
  background: #2896e9 !important;
  border: none !important;
  color: #ffffff !important;
} */
.rbc-header {
  border-color: #eaeaec;
  color: #75757a;
  padding: 5px 15px;
  font-size: 1rem !important;
  font-weight: 500;
}
.rbc-time-content{
  border: none;
}
.rbc-event {
  position: relative; 
  left: 0%!important;
}

.rbc-event-label{
  font-size: 10px;
  padding-right: 0px;
  flex: 1 1!important;
}
.rbc-date-cell {
  text-align: left;
  padding-left: 10px;
  padding-top: 5px;
}
.rbc-button-link {
  color: #75757a;
  font-weight: 500 !important;
  font-size: 1rem;
  margin-bottom: 10px;
}
.rbc-time-slot {
  color: #75757a;
  font-weight: 500;
  z-index: 1;
}
.rbc-event-content {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  line-height: 1.3 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap; 
  /* z-index: 999 !important; */
}
.rbc-row-segment {
  margin-top: 5px;
}
.rbc-off-range-bg {
  background: #f2f2f2;
}
.rbc-time-view .rbc-row{
  min-height: 35px;
}
.rbc-event,
.rbc-background-event {
  z-index: 2;
}
.rbc-time-view .rbc-allday-cell{
display: none;
}
.rbc-event-label {
  font-weight: 600 !important;
  margin-left: 5px !important;
}

.rbc-events-container {
  margin-right: 0 !important;
  /* width: 100% !important; */
}
.rbc-calendar {
  padding-left: 0 !important;
  padding-bottom: 0 !important;
  /* border-left: 1px solid #eaeaec; */
}
/* Swip button */
.swipezor-but{
  
  border: none !important ;
  color: #ffffff !important;
}
.Style_buttonContainer__oAUlr {
  display: block !important;
  padding: 0 10% !important;
}
.swipezor-overlay {
  background:none !important;
  left: 5px !important;
}
.swipezor-caret-wrapper{
  background: none !important;
}
/* ///////////////////////// */