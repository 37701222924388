.flexContainer {
  /*display: flex;*/
  /*align-items: center;*/
}
.flex1 {
  flex: 1;
  min-width: 170px;
  margin: 0px 5px;
}
.flex122 {
  /* flex: 1; */
  width: 33%;
  min-width: 170px;
  margin: 0px 5px;
}
.plainPaper {
  border-radius: 10px;
  padding: 1.2rem;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 0 8px rgb(0 0 0 / 15%);
}
.heading {
  font-size: 0.8rem;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
}
.countryField {
  flex: 0.5;
}
.firstRow {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.firstRow221 {
  flex: 1;
  /* width: 33%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.fieldFlex {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}
.totalWrap {
  width: 100%;
  height: 50px;
  background: #f5f7fb 0% 0% no-repeat padding-box;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 600;
  font-size: 0.8rem;
  margin-top: 5px;
}
.inner {
  margin-left: 20px;
}
.inner > span {
  color: #2896e9;
}

.addBtn {
  border: 1px solid #4a4a4a59 !important;
  width: 100%;
  padding: 15px !important;
  margin-top: 10px !important;
  border-radius: 5px !important;
  font-size: 0.8rem !important;
}

.addBtn:hover {
  background: #f7a728;
  color: white;
}

.dltBtn {
  border: 1px solid red !important;
  color: red !important;
  font-size: 0.8rem !important;
  padding: 10px 20px !important;
  margin-top: 8px !important;
}

.dltBtn:hover {
  background: red;
  color: white !important;
}

.cell {
  margin-bottom: 40px;
}
.headerTitle {
  /*margin-bottom: 15px;*/
  /*padding-bottom: 5px;*/
  /*margin-left: 15px;*/
  /*border-bottom: 1px solid;*/
  /*width: 100%;*/
  flex: 4;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.sku {
  font-size: 0.8rem;
  margin-left: 40px;
  margin-right: 10px;
}

.skuId {
  display: flex;
  align-items: center;
}

.rightBtn {
  width: 200px;
}
.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.skuBtn {
  background: #f7a728 !important;
  padding: 11px 16px !important;
  color: white !important;
  border-radius: 5px !important;
  margin-bottom: 15px !important;
}

.removeBtn {
  color: red !important;
  font-size: 0.7rem !important;
  margin-left: 20px !important;
}

.addition {
  color: #2896e9 !important;
  font-weight: 600 !important;
  font-size: 0.7rem !important;
  padding-top: 10px !important;
}
.radioWrapper {
  margin-bottom: 10px;
}
