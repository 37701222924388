.candidateInfoWrapper {
  display: flex;
  flex-direction: column !important;
  padding: 1.2rem;
}
.leftField {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.imageDesWrapper {
  display: flex;
  gap: 10px;
}
.desWrapper {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  gap: 2px;
}
.key {
  color: #818181;
  font-weight: 500;
  margin-bottom: 3px;
}
.value {
  font-weight: 600;
  width: 180px;
  color: black;
  display: inline-block;
}
.candidateImg {
  width: 3rem;
  height: auto;
  border-radius: 50%;
}
.headingWrapper > span {
  font-weight: 600;
  font-size: 1rem;
}
.rightField {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mobileViewImage{
  display: none !important;
}

.bioData{
  display: flex !important;
  margin-top: 10px;
}
.imageWrapperContMobile{
  display: none !important;
}

@media screen and (max-width:769px) {
  .imageWrapperCont{
    display: none !important;
  }
  .key {
    display: flex;
    flex-direction: column;
    color: #818181;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .candidateInfoWrapper {
    display: flex;
    flex-direction: column !important;
    padding: 1.2rem;
    justify-content: space-between !important;
  }
  .value {
    font-weight: 600;
    width: fit-content !important;
    color: black;
    display: inline-block;
  }
  .mobileViewImage{
    display: block !important;
  }
  .imageWrapperContMobile{
    display: block !important;
  }
  .imageAlignCenter{
    display: flex;
    justify-content: center !important;
  }
  .candidateImgMobile{
    height:100px;
    width: 100px;
  }
  .bioData{
    display: flex !important;
    margin-top: 10px;
    justify-content: center;
    width: 100%;
  }
}