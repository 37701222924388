.newLine {
  height: 4px;
  width: 20px;
  border-radius: 10px;
  background-image: linear-gradient(265deg, #7467f0, #2896e9) !important;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.title {
  font-size: 1rem;
  font-weight: 600;
}
.upperWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.intName{
  font-weight: 600;
  margin-bottom: 4px;
}
.upperFlex {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  margin-top: 15px;
}
.printFlex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 30px;
  padding-top: 10px;
}
.tableCont {
  padding: 10px 10px;
  max-height: 200px;
  overflow: scroll;
}
.resetWrapper {
  display: flex;
}
.resetPasswordWrapper {
  padding: 1.2rem;
}
.subHeading {
  font-weight: bold;
  margin-bottom: 0.875rem;
  font-size: 1rem;
  position: sticky;
}
.intContainer {
  max-height: 200px;
  overflow-y: auto;
  margin-top: 0.5rem;
}
.interviewerWrapper {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  font-size: 0.875rem;
}
.fieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}
.checkBox > label {
  font-size: 0.75rem;
}
.checkBox {
  display: flex;
  align-items: center;
}
.value {
  color: #818181;
}
.link {
  text-decoration: underline;
  cursor: pointer;
  color: #2896e9;
}
.key {
  min-width: 200px;
  color: #161616;
}
.headingWrapper {
  display: flex;
  font-size: 0.875rem;
  margin-bottom: 10px;
  font-weight: bold;
  color: #161616;
}